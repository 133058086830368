import { IPatientCreateAdditionalModelSnapShot } from "../cl-patient-create-additional.store";

export const AdditionalInfoInitialState: IPatientCreateAdditionalModelSnapShot = {
  id: "0",
  CPTCodeId: {
    name: "CPT code",
    value: "",
    type: "select",
    options: [],
    fieldId: "CPTCodeId"
  },
  primaryInsuranceCompany: {
    name: "Primary insurance company",
    value: "",
    type: "input",
    maxLength: 20,
    fieldId: "primaryInsuranceCompany"
  },
  policyNumber: {
    name: "Member ID",
    value: "",
    type: "input",
    maxLength: 20,
    fieldId: "policyNumber"
  },
  groupNumber: {
    name: "Group number",
    value: "",
    type: "input",
    maxLength: 10,
    isOptional: true,
    fieldId: "groupNumber"
  },
  secondaryInsuranceCompany: {
    name: "Secondary insurance company",
    value: "",
    maxLength: 20,
    isOptional: true,
    type: "input",
    fieldId: "secondaryInsuranceCompany"
  },
  secPolicyNumber: {
    name: "Sec. ins. Member ID",
    value: "",
    type: "input",
    isOptional: true,
    maxLength: 20,
    fieldId: "secPolicyNumber"
  },
  secGroupNumber: {
    name: "Sec Ins. Group number",
    value: "",
    type: "input",
    isOptional: true,
    maxLength: 10,
    fieldId: "secGroupNumber"
  },
  subscribersFirstName: {
    name: "Subscribers first name",
    value: "",
    type: "input",
    maxLength: 20,
    fieldId: "subscribersFirstName"
  },
  subscribersLastName: {
    name: "Subscribers last name",
    value: "",
    type: "input",
    maxLength: 20,
    fieldId: "subscribersLastName"
  },
  subscribersRelationship: {
    name: "Subscribers relationship to patient",
    defaultValue: {
      value: "Self",
      label: "Self"
    },
    type: "select",
    fieldId: "subscribersRelationship",
    options: [{
      value: "Self",
      label: "Self"
    }, {
      value: "Spouse",
      label: "Spouse"
    }, {
      value: "Child",
      label: "Child"
    }, {
      value: "Other",
      label: "Other"
    }]
  },
  specifyRelationship: {
    name: "Specify Relationship",
    value: "",
    type: "input",
    maxLength: 20,
    fieldId: "specifyRelationship"
  },
  subscribersDOB: {
    name: "Subscribers DOB",
    value: "",
    type: "dateTime",
    isOptional: true,
    maxLength: 10,
    fieldId: "subscribersDOB"
  }
};
